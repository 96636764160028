.hero-swiper {
  position: relative;
  width: 100%;
  height: 100%;

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    color: white;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;  // Asegura que no haya margen
      padding: 0; // Asegura que no haya padding
    }

    .hero__card-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0;
      margin: 0;
      position: relative;

      .hero__img {
        position: relative;
        z-index: 1;
      }

      .hero__card {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        gap: 10px;
        padding: 2rem 1rem;
        color: white;
        position: absolute;
        z-index: 2;

        &--title {
          font-family: "Lobster", cursive;
          font-size: 32px;
          font-weight: 400;
        }

        &--description {
          font-size: 20px;
        }

        a {
          margin-top: 1rem;
          font-size: 24px;
          text-decoration: none;
          background-color: var(--primary-color);
          color: var(--text-primary-color);
          text-transform: capitalize;
          font-weight: 500;
          padding: 0.5rem 2rem;
          border-radius: 5px;
        }
      }
    }
    .hero__card--links {
      display: flex;
      justify-content: center;  // Centrar los botones
      gap: 20px;  // Espacio entre los botones
      margin-top: 1rem;  // Separar los botones del contenido superior
    }
    
    .hero__login {
      text-decoration: none;
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border-radius: 5px;
      transition: background-color 0.3s;
    }
    
    .hero__login:hover {
      background-color: #0056b3;
    }
  }
}

// Ajuste para pantallas pequeñas
@media (max-width: 480px) {
  .hero-swiper {
    .swiper-slide {
      .hero__card-container {

        .hero__card {
          justify-content: flex-start; // Alinea más a la izquierda el contenido
          align-items: flex-start; // Alinea el texto a la izquierda

          &--title {
            font-size: 20px; // Disminuir el tamaño del título en pantallas pequeñas
            text-align: left; // Alinearlo a la izquierda
            margin-left: 1rem; // Desplazarlo hacia la izquierda
          }

          &--description {
            font-size: 16px; // Disminuir el tamaño de la descripción
          }

          a {
            font-size: 18px; // Reducir el tamaño de los botones
            padding: 0.3rem 1rem; // Reducir el padding de los botones para que sean más pequeños
          }
        }
      }
    }
  }
}

// Estilos para pantallas medianas
@media (min-width: 768px) {
  .hero-swiper {
    .swiper-slide {
      .hero__card-container {
        height: 655px;  // Ajuste para pantallas medianas
        justify-content: flex-start;

        .hero__card {
          width: 60%;
          align-items: flex-start;
          text-align: left;
          margin-left: 2rem;
          gap: 25px;

          &--title {
            font-size: 40px;
          }

          &--description {
            font-size: 22px;
          }
        }
      }
    }
  }
}

// Estilos para pantallas grandes
@media (min-width: 1024px) {
  .hero-swiper {
    .swiper-slide {
      .hero__card-container {
        height: 100%;

        .hero__card {
          &--title {
            font-size: 48px;
            width: 70%;
          }

          &--description {
            font-size: 24px;
            width: 70%;
          }
        }
      }
    }
  }
}

// Estilos para pantallas extra grandes
@media (min-width: 1440px) {
  .hero-swiper {
    .swiper-slide {
      .hero__card-container {
        .hero__card {
          &--title {
            font-size: 56px;
          }

          &--description {
            font-size: 32px;
          }
        }
      }
    }
  }
}





// .section__hero {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
// }

// .hero__content {
//   display: flex;
//   gap: 20px; // Espacio entre el carrusel y el video
//   align-items: center;
// }

// .hero__container {
//   max-width: 800px;
//   width: 100%;
//   margin: 0 auto;
//   overflow: hidden;
// }

// .hero-swiper {
//   .slick-list {
//     padding: 0 !important;
//   }

//   .slick-slide {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     margin: 0;
//   }

//   .hero__slide {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   img.hero__img {
//     width: 100%;
//     height: auto;
//     object-fit: cover;
//     border-radius: 10px;
//   }
// }

// /* Imagen del Video (sin cambiar su tamaño) */
// .video-container {
//   position: relative;
//   width: 400px; // ✅ Mantiene el tamaño original
//   height: 395px;
//   background: #000;
//   border-radius: 10px;
//   overflow: hidden;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   transition: transform 0.2s ease-in-out;
// }

// .video-container:hover {
//   transform: scale(1.05);
// }

// .video-thumbnail {
//   width: 100%;
//   height: 100%;
//   object-fit: cover; // ✅ Se mantiene sin distorsionarse
//   border-radius: 10px;
//   display: block;
// }

// @media (max-width: 768px) {
//   .video-container {
//     display: none;
//   }
// }

// /* Modal del Video */
// .video-modal {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .video-modal-content {
//   position: relative;
//   background: rgba(0, 0, 0, 0.9);
//   padding: 10px;
//   border-radius: 10px;
//   width: 80%;
//   max-width: 800px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .video-frame {
//   width: 100%;
//   height: auto;
//   border-radius: 10px;
//   object-fit: contain;
// }

// /* Botón de cierre */
// .video-close-btn {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
//   transition: opacity 0.2s ease-in-out;
// }

// .video-close-btn:hover {
//   opacity: 0.7;
// }
