.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--primary-color);
  color: var(--text-primary-color);
  padding: 1.5rem 0;

  &-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 2.5rem;
  }

  &__logo {
    margin-bottom: 0.5rem;
  }

  &__col {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .col-wrap {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
    }
  }

  &__col--info .col-wrap {
    gap: 15px;

    a.tyc {
      text-decoration: none;
      font-size: 14px;
      color: var(--text-primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &__col--contact {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__col--newsletter {
    form {
      width: 100%;

      fieldset {
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        input {
          border: 0;
          border-bottom: 1px solid var(--text-primary-color);
          background-color: transparent;
          text-align: center;
          color: var(--text-primary-color);
          transition: border 0.2s ease-in-out;
          width: 100%;
          padding: 0.2rem;

          &::placeholder {
            color: rgba(255, 255, 255, 0.6);
          }

          &:focus-visible {
            outline: none;
            border-bottom: 3px solid var(--text-primary-color);
          }
        }

        button {
          border: none;
          background-color: var(--text-primary-color);
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0.4rem;
        }
      }
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__description {
    text-align: center;
    font-size: 0.8rem;
  }

  &__logo {
    text-decoration: none;
    font-family: "Lobster", cursive;
    font-size: 40px;
    color: var(--text-primary-color);
  }

  &__contact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;

    a {
      font-size: 14px;
      text-decoration: none;
      color: var(--text-primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &:nth-child(1):before {
        content: "";
        width: 14px;
        height: 14px;
        background-image: url("../../assets/icon/phone.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 10px;
      }

      &:nth-child(2):before {
        content: "";
        width: 14px;
        height: 14px;
        background-image: url("../../assets/icon/email.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 10px;
      }
    }

    address {
      font-size: 14px;
      color: var(--text-primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &:before {
        content: "";
        width: 14px;
        height: 14px;
        background-image: url("../../assets/icon/map.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 10px;
      }
    }
  }

  &__rrss {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: auto;
    height: auto;

    &link {
      img {
        width: auto;
        height: auto;
      }
    }
  }
  
  .footer__rrsslink {
  
    img {
      width: 30px; /* Ajusta el tamaño del ícono */
      height: 30px;
    }
  }
  
}



@media (min-width: 768px) {
  .footer {
    padding: 3rem 2rem;

    &-container {
      flex-direction: row;
      align-items: flex-start;
    }

    &__col {
      .col-wrap {
        align-items: flex-start;
      }
    }

    &__col--info {
      width: 33.3333333333%;
    }

    &__col--contact {
      width: 33.3333333333%;
    }

    &__col--newsletter {
      width: 33.3333333333%;
    }

    &__contact {
      align-items: flex-start;
    }

    &__description {
      text-align: start;
      font-size: 0.8rem;
    }
  }
}

@media (min-width: 1440px) {
  .footer {
    &-container {
      width: 80%;
    }

    &__col--newsletter {
      form {
        fieldset {
          flex-direction: row;
        }
      }
    }
  }
}
